import { Fragment } from "react"
import "../../css/styles.css"
import { Button, Stack } from "react-bootstrap"
/*
Zu styles.css hinzufügen

.modal_dark_background { background-color: rgba(0, 0, 0, 0.2);width: 100vw;height: 100vh;z-index: 0;top: 50%;left: 50%;transform: translate(-50%, -50%);position: absolute; }
.modal_centered { position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%); }
.modal { width: 250px;height: 170px;background: white;color: white;z-index: 10;border-radius: 16px;box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04); }
*/

/* Verwendung:

import { Button } from "react-bootstrap";
import ErrorModal from "./ui-utils/Error-Modal";
import { Fragment, useState } from "react"

const OtherComponent = () => {

    let [show_modal, set_show_modal] = useState()

    return (
        <Fragment>
            { show_modal && <ErrorModal set_show_modal={set_show_modal} error_msg={"Error message comes here..."}/>}
            <Button onClick={ () => { set_show_modal(!show_modal) }}>Show Modal</Button>
        </Fragment>)
}

export default OtherComponent

*/

const ErrorModal = ( {set_show_modal, error_msg} /* ACHTUNG: Dieser Name hier muss exakt mit dem Namen in let [show_modal, set_show_modal] = useState(false) übereinstimmen!!! */) => {
    return (
        <Fragment>
            <div className="modal_dark_background" onClick={ () => { set_show_modal(false) } }>
                
                <div className="modal_centered">
                    <div className="modal_div p-3">
                        <h1>❌</h1>
                        <p>{error_msg}</p>
                        <Button className="mt-3" variant="danger" onClick={ () => { set_show_modal(false) } }>Verstanden</Button>
                    </div>
                </div>
            </div>
        </Fragment>)
}

export default ErrorModal