import { Container } from "react-bootstrap/esm"
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchInput from "./search/Search-Input";
import { Button } from "react-bootstrap";
import { SyncNotifier } from "./utils/Sync-Manager";
import NetworkStatus from "./utils/Network-Status";
import { useEffect, useState } from "react";
import AppParams from "../store/App-Parameters";
import { useDispatch } from "react-redux";
import { wordpack_actions } from "../store/Wordpack-Slice";


const ChooseStruggleType = () =>  {

    let navigate = useNavigate()
    let dispatch = useDispatch()
    const [show_wrong_past_2_days_btn, set_show_wrong_past_2_days_btn] = useState(false)
    const [show_choose_wordpack_btn, set_show_choose_wordpack_btn] = useState(false)
    
    
    useEffect( () => { 
        if( AppParams.may_pass_struggle_words() && !AppParams.may_pass_wrong_past_2_days() )
            set_show_wrong_past_2_days_btn(true)
        if( AppParams.may_pass_struggle_words() && AppParams.may_pass_wrong_past_2_days() ) {
            set_show_choose_wordpack_btn(true) 
            set_show_wrong_past_2_days_btn(true)
            navigate("/choose-word-pack")
        }
    }, [])

    const start_struggle_training_style = ( training_style ) => {
        AppParams.training_style = training_style
        AppParams.wordpack_id = AppParams.STRUGGLE_WORDPACK_ID
        dispatch( wordpack_actions.set_training_style( { training_style : training_style } ) )
        navigate("/unified-trainer") 
    }

    const start_wrong_past_2_days_training = () => {
        AppParams.training_style = AppParams.FORWARD
        AppParams.wordpack_id = AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID
        AppParams.ugly_fix__first_time_call = 2
        dispatch( wordpack_actions.set_training_style( { training_style : AppParams.FORWARD } ) )
        navigate("/unified-trainer") 
    }

    return <Container className="text-center p-4">
        <NetworkStatus />
        <SyncNotifier />
        <Row>
            <Col>
                <SearchInput/>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <div className="d-grid gap-2">
                    <Button variant="secondary" size="lg" onClick={ () => { start_struggle_training_style( AppParams.LISTENING ) } }>Listening Struggle</Button>
                </div>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <div className="d-grid gap-2">
                    <Button variant="secondary" size="lg" onClick={ () => { start_struggle_training_style( AppParams.INVERSE ) } } >Backward Struggle</Button>
                </div>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <div className="d-grid gap-2">
                    <Button variant="secondary" size="lg" onClick={ () => { start_struggle_training_style( AppParams.FORWARD ) } }>Forward Struggle</Button>
                </div>
            </Col>
        </Row>

        { show_wrong_past_2_days_btn &&
            <Row className="mt-3">
                <Col>
                    <div className="d-grid gap-2">
                        <Button variant="warning" size="lg" onClick={ () => { start_wrong_past_2_days_training() } }>Falsche Wörder der letzten 2 Tage</Button>
                    </div>
                </Col>
            </Row>
        }

        { show_choose_wordpack_btn && 
            <Row className="mt-3">
                <Col>
                    <div className="d-grid gap-2">
                        <Button variant="success" size="lg" onClick={ () => { navigate("/choose-word-pack") } }>Wähle Wordpacks</Button>
                    </div>
                </Col>
            </Row>
        }
    </Container>
}

export default ChooseStruggleType
