class ImportanceLevel {

    static ok = "ok"
    static neutral = "neutral"
    static upcoming = "upcoming"
    static recommended = "recommended"
    static required = "required"


    // Damit die Farben (bzw. Tage) korrekt in China abgebildet werden, muss dieser Verscheibungterm aufaddiert werden
    // 1 - 0.25 = 0.75 --> Das ist die Korrektur, wenn man in China ist - Zeitverschiebung 0.25*24h = 6h
    static correction_term = 0.75
    static get_delta = (key, wordpack_data) => {
        return Math.floor( ImportanceLevel.correction_term + (((new Date().getTime()) - Date.parse(wordpack_data[key]))/1000/60/60/24) )
    }


    static sort_by_importance_level = (wordpacks_dict, look_at = "normal") => {
        ImportanceLevel.set_importance_level(wordpacks_dict)

        let sorted_by_importance_level_array = []
        let ordered_importance_levels = [ImportanceLevel.ok, ImportanceLevel.required, ImportanceLevel.recommended, ImportanceLevel.upcoming, ImportanceLevel.neutral]
        let wordpack_ids = Object.keys(wordpacks_dict).filter(key => look_at == "normal" ? parseInt(key) < 1000 : 1000 <= parseInt(key))
        ordered_importance_levels.forEach( importance_level => {
            ImportanceLevel.normalize( importance_level, wordpacks_dict )
            let selected_by_importance_level_array = []
            for( let i = 0; i < wordpack_ids.length; ++i) {
                let wordpack = wordpacks_dict[wordpack_ids[i]]
                wordpack.wordpack_id = parseInt(wordpack_ids[i])
                if( wordpack.importance_level == importance_level)
                    selected_by_importance_level_array.push(wordpack)
            }
            selected_by_importance_level_array.sort( (a,b) => { return ImportanceLevel.sorting_function(a, b, importance_level) } )
            sorted_by_importance_level_array.push(...selected_by_importance_level_array) // push(...array) entspricht python extend
        })
        return sorted_by_importance_level_array
    }


    static sorting_function = (wp1, wp2, importance_level) => {
        if( wp1.normalized_delta_days == wp2.normalized_delta_days )
            return 0 < (wp1.wordpack_id - wp2.wordpack_id) ? -1 : 0

        if( importance_level == ImportanceLevel.neutral ) 
            return 0 < (wp1.normalized_delta_days - wp2.normalized_delta_days) ? -1 : 0
        else 
            return 0 < (wp1.normalized_delta_days - wp2.normalized_delta_days) ? 0 : -1
    }


    static normalize = (importance_level, wordpacks_dict) => {
        let max_value = 1
        let wordpack_ids = Object.keys(wordpacks_dict)
        for( let i = 0; i < wordpack_ids.length; ++i) {
            let wordpack = wordpacks_dict[wordpack_ids[i]]
            if( wordpack.importance_level == importance_level ) 
                max_value = max_value < wordpack.delta_days ? wordpack.delta_days : max_value
        }

        for( let i = 0; i < wordpack_ids.length; ++i) {
            let wordpack = wordpacks_dict[wordpack_ids[i]]
            if( wordpack.importance_level == importance_level ) 
                wordpack.normalized_delta_days = wordpack.delta_days / max_value
        }
    }


    static set_importance_level = (wordpacks_dict) => {
        let wordpack_ids = Object.keys(wordpacks_dict)
        for( let i = 0; i < wordpack_ids.length; ++i) {
            let wordpack = wordpacks_dict[wordpack_ids[i]]

            wordpack.delta_days = ImportanceLevel.get_delta("0", wordpack )
            
            // Initally always default to neutral
            wordpack.importance_level = ImportanceLevel.neutral

            if( 0 <= wordpack["0_cnt"] && wordpack["0_cnt"] <= 10 ) {
                if( 0 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( 10 < wordpack["0_cnt"] && wordpack["0_cnt"] <= 13 ) {
                if( 1 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 2 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 3 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }
            
            if( 13 < wordpack["0_cnt"] && wordpack["0_cnt"] <= 17 ) {
                if( 4 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 5 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 7 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( 17 < wordpack["0_cnt"] && wordpack["0_cnt"] <= 20 ) {
                if( 9 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 10 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 12 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( 20 < wordpack["0_cnt"] && wordpack["0_cnt"] <= 25 ) {
                if( 12 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 14 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 15 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( 25 < wordpack["0_cnt"] && wordpack["0_cnt"] <= 30 ) {
                if( 18 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 19 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 20 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( 30 < wordpack["0_cnt"] && wordpack["0_cnt"] <= 40 ) {
                if( 21 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 22 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 23 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( 40 < wordpack["0_cnt"] ) {
                if( 57 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.upcoming
                if( 58 <= wordpack.delta_days )
                    wordpack.importance_level = ImportanceLevel.recommended
                if( 60 <= wordpack.delta_days)
                    wordpack.importance_level = ImportanceLevel.required
            }

            if( wordpack.delta_days == 0 )
                wordpack.importance_level = ImportanceLevel.ok
        }
    }
}

export default ImportanceLevel