import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap/"
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import useHttp from '../../hooks/useHttp';
import "../../css/styles.css"
import CommentsEditor from "../Comments-Editor";

const SearchResult = (props) => {

    let search_result = props.search_result;
    let position = props.position
    let [show_comments_editor, set_show_comments_editor] = useState(false)

    useEffect( () => { 
        set_show_comments_editor(search_result.comments != null && 0 < search_result.comments.length) 
    }, [])

    return (<Fragment>
        <Container className={ "py-1 " + (position % 2 != 0 ? "search_result_odd_row" : "search_result_even_row") }>
            <Row>
                <Col>
                    <Stack direction="vertical">
                        <div>{ search_result.chinese }</div>
                        <div>{ search_result.pinyin }</div>
                    </Stack>
                </Col>
                <Col>
                    { search_result.german } 
                    { !show_comments_editor && <p onClick={ () => { set_show_comments_editor(true) } } className="search_results_add_comment_p">+</p>}
                    { show_comments_editor && <p onClick={ () => { set_show_comments_editor(false) } } className="search_results_add_comment_p">-</p>}
                </Col>
            </Row>
            <Row>
                {show_comments_editor &&  <CommentsEditor update_redux_store={false} word={search_result}/> }
            </Row>
        </Container>
    </Fragment>)

}

export default SearchResult