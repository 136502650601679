import { Fragment, useEffect, useState } from "react"
import { Button, Row, Col, Stack, Spinner } from "react-bootstrap"
import useHttp from "../../hooks/useHttp"
import ErrorModal from "../utils/Error-Modal"
import { useSelector, useDispatch } from 'react-redux';
import AppParams from "../../store/App-Parameters";
import { wordpack_actions } from "../../store/Wordpack-Slice";

const StruggleButtons = (props) => {

    const dispatch = useDispatch()
    const words = useSelector( state => state.wordpack.words )
    const current_words_index = useSelector( state => state.wordpack.current_words_index )

    let [forward_btn_state, set_forward_btn_state] = useState(false)
    let [forward_is_updating, set_forward_is_updating] = useState(false)
    let [inverse_btn_state, set_inverse_btn_state] = useState(false)
    let [inverse_is_updating, set_inverse_is_updating] = useState(false)
    let [listening_btn_state, set_listening_btn_state] = useState(false)
    let [listening_is_updating, set_listening_is_updating] = useState(false)
    let [all_btns_disabled, set_all_btns_disabled] = useState(false)
    let { isLoading, error, sendRequest } = useHttp();
    let [show_modal, set_show_modal] = useState(false)
    let [modal_error_msg, set_modal_error_msg] = useState("")

    useEffect( () => {
        if( 0 <= current_words_index ) {
            set_forward_btn_state( words[current_words_index].forward_struggle == 1 ? true : false )
            set_inverse_btn_state( words[current_words_index].inverse_struggle == 1 ? true : false )
            set_listening_btn_state( words[current_words_index].listening_struggle == 1 ? true : false  ) 
        }
    }, [current_words_index])


    const toggle_forward_struggle = () => {
        let struggle_type = AppParams.FORWARD
        set_forward_is_updating(true)
        toggle_struggle_word( struggle_type)
    }


    const toggle_inverse_struggle = () => {
        let struggle_type = AppParams.INVERSE
        set_inverse_is_updating(true)
        toggle_struggle_word( struggle_type)
    }


    const toggle_listening_struggle = () => {
        let struggle_type = AppParams.LISTENING
        set_listening_is_updating(true)
        toggle_struggle_word( struggle_type)
    }


    const toggle_struggle_word = (struggle_type) => {
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},
            body: {
                "service_type" : "set_struggle_word",
                "word_id" : words[current_words_index].word_id,
                "struggle_type" : struggle_type,
                "training_style" : AppParams.training_style,
            },
            onSuccessFunction : (response_json) => {
                set_all_btns_disabled(false)
                set_forward_is_updating(false)
                set_inverse_is_updating(false)
                set_listening_is_updating(false)
                if(response_json.result === "success") {
                    set_forward_btn_state( response_json.forward_struggle == 1 )
                    set_inverse_btn_state( response_json.inverse_struggle == 1 )
                    set_listening_btn_state( response_json.listening_struggle == 1 )
                    dispatch( wordpack_actions.update_struggle_types( { 
                        forward_struggle : response_json.forward_struggle,
                        inverse_struggle : response_json.inverse_struggle,
                        listening_struggle : response_json.listening_struggle
                    } ) )
                } else {
                    set_modal_error_msg( response_json.reponse_payload )
                    set_show_modal(true)
                }
            }
        };
        set_all_btns_disabled(true)
        sendRequest(requestConfig);
    }

    return (
        <Fragment>
            { show_modal && <ErrorModal set_show_modal={set_show_modal} error_msg={modal_error_msg}/>}
            { 0 <= current_words_index && <Stack direction="horizontal">
                
                <div className="struggle_buttons_btn_container_div me-1">
                    { !forward_is_updating && 
                        <Button variant="secondary" 
                                className={ forward_btn_state && "struggle_buttons_green_btn"} 
                                disabled={ all_btns_disabled }
                                onClick={ () => { toggle_forward_struggle() } }>
                            FS
                        </Button> 
                    }
                    { forward_is_updating && <Spinner animation="border" style={{color: "limegreen"}} /> }
                </div> 

                { AppParams.training_style != AppParams.HANZI && 
                <div className="struggle_buttons_btn_container_div mx-1">
                    { !inverse_is_updating && 
                        <Button variant="secondary" 
                                className={ inverse_btn_state && "struggle_buttons_green_btn" } 
                                disabled={ all_btns_disabled }
                                onClick={ () => toggle_inverse_struggle() } >
                            IS
                        </Button> }
                    { inverse_is_updating && <Spinner animation="border" style={{color: "limegreen"}} /> }
                </div>
                }

                { AppParams.training_style != AppParams.HANZI && 
                <div className="struggle_buttons_btn_container_div ms-1">
                    { !listening_is_updating && 
                        <Button variant="secondary" 
                                className={ listening_btn_state && "struggle_buttons_green_btn"} 
                                disabled={ all_btns_disabled }
                                onClick={ () => toggle_listening_struggle() }>
                            LS
                        </Button> }
                    { listening_is_updating && <Spinner animation="border" style={{color: "limegreen"}} /> }
                </div>
                }
            </Stack> }
        </Fragment>)
}

export default StruggleButtons