import { Routes, Route, Navigate } from 'react-router-dom';
import ChooseStruggleType from './ui/Choose-Struggle-Type';
import ChooseWordPack from './ui/choose-word-pack/Choose-Word-Pack';
import { Fragment } from 'react';
import UnifiedTraining from './ui/training/Unified-Training';
import ChooseSortedWordPack from './ui/choose-word-pack/Choose-Sorted-Word-Pack';

/*
    - LocalStorage Sync Manager auch in ChooseWordPacks anzeigen...
    - Comment-Tool überarbeiten (okay)
    - Jetzt hochladen Screen schalten (Nicht erforderlich!)
    - Letzte 2 Tag wdh (holy cow... was für eine Arbeit!!! Schwierigen Bug korrigiert)

    - HanZi Trainer
    - Beschränkung der Länge der zu wiederholenden Wordpack entfernen
    - App muss deployt und getestet werden...
*/
const App = (props) => {
    window.baseURL = document.getElementById("url").innerHTML;
    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<Navigate replace to="/choose-struggle-type" />}/>
                <Route path="/choose-struggle-type" element={<ChooseStruggleType />} />
                <Route path="/choose-word-pack" element={<ChooseSortedWordPack/>} />
                <Route path="/unified-trainer" element={<UnifiedTraining/>} />
                <Route path="*" element={<ChooseStruggleType/>}/>
            </Routes>
        </Fragment>
      );
     
}


export default App;
