import { configureStore } from "@reduxjs/toolkit";
import wordpack_slice from "./Wordpack-Slice";


const store = configureStore({
    reducer : {
        wordpack : wordpack_slice.reducer,
    }
})

export default store