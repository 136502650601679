import { Fragment, useEffect, useRef, useState } from "react"
import { Button, Container, Form, Spinner, Stack } from "react-bootstrap"
import useHttp from "../hooks/useHttp"
import "../css/styles.css"
import { useDispatch } from "react-redux"
import { wordpack_actions } from "../store/Wordpack-Slice"
import AppParams from "../store/App-Parameters"

const CommentsEditor = (props) => {
    let word = props.word
    let update_redux_store = props.update_redux_store
    
    let dispatch = useDispatch()
    let comment_input_ref = useRef()
    let { isLoading, error, sendRequest } = useHttp();
    let [error_msg, set_error_msg] = useState("")
    let [show_update_ok_arrow, set_show_update_ok_arrow] = useState(false)
    let [textarea_text, set_textarea_text] = useState( word.comments == null ? "" : word.comments )
    let [show_add_comment, set_show_add_comment] = useState(false)

    useEffect( () => {
        set_show_add_comment(props.show_add_comment)
    }, [])

    const submitHandler = (event) => {
        event.preventDefault();
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},
            body: {
                "service_type" : "update_comment",
                "word_id" : word.word_id,
                "new_comment" : comment_input_ref.current.value,
                "training_style" : AppParams.training_style,
            },
            onSuccessFunction : (responseJson) => {
                if(responseJson.result === "success") {
                    if( update_redux_store )
                        dispatch( wordpack_actions.update_comment( { new_comment : comment_input_ref.current.value } ))
                    else
                        word.comments = comment_input_ref.current.value
                    set_show_update_ok_arrow(true)
                    setInterval( () => { set_show_update_ok_arrow(false) } , 1000)
                } else {
                    set_error_msg(responseJson.reponse_payload)
                }
            }
        };
        sendRequest(requestConfig);
    }

    const onChangeHandler = (event) => {
        set_textarea_text(event.target.value)
    }

    let edit_comment_layout = ( <Stack direction="vertical" className="mt-2">
                                    <Form.Control as="textarea" ref={comment_input_ref}  onChange={onChangeHandler} value={ textarea_text } /> 
                                    { show_update_ok_arrow && <p className="m-0 p-0 text-center comments_editor_updated_info_p">✅ Updated</p>}
                                    <div className="mt-2">
                                        { !isLoading && <Button className="comments_editor_100_btn" variant="secondary" type="submit" onClick={submitHandler}>Update</Button> }
                                        { isLoading && <Spinner animation="border" variant="spinner-border text-warning" /> }
                                    </div>
                                    <div className="mt-2">
                                        { 0 < error_msg.length && <p className="error-p">Fehler: {error_msg}</p> }
                                        { (error != null && 0 < error.length) && <p className="error-p">useHttp Error: {error}</p> }
                                    </div>
                                </Stack>)

    let add_comment_layout = (
        <div className="text-center">
            <Button variant="secondary" className="rounded-circle comments_editor_add_comment_btn" size="lg" onClick={ () => { set_show_add_comment(false) }}>+</Button>
        </div>
    )
    return (<Fragment>
                {show_add_comment && add_comment_layout}
                {!show_add_comment && edit_comment_layout}
            </Fragment>)
}

export default CommentsEditor