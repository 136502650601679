class AppParams {

    static FORWARD = "forward"
    static INVERSE = "inverse"
    static LISTENING = "listening"
    static HANZI = "hanzi"
    static STRUGGLE_WORDPACK_ID = -2
    static WRONG_PAST_2_DAYS_WORDPACK_ID = -3
    static LOCAL_STORAGE_STRUGGLE_KEY = "struggle_words_last_time_repeated"
    static LOCAL_STORAGE_WRONG_PAST_2_DAYS_KEY = "forward_wrong_past_2_days"
    static wordpack_id = -1
    static training_style = null

    static set_struggle_words_passed = () => {
        let now = new Date()
        let str_now = now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate()
        localStorage.setItem( AppParams.LOCAL_STORAGE_STRUGGLE_KEY, str_now)
    }

    static may_pass_struggle_words = () => {
        let now = new Date()
        let str_now = now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate()
        let last_repeated_str_date = localStorage.getItem( AppParams.LOCAL_STORAGE_STRUGGLE_KEY )
        return last_repeated_str_date != null && last_repeated_str_date == str_now
    }

    static set_wrong_past_2_days_passed = () => {
        let now = new Date()
        let str_now = now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate()
        localStorage.setItem( AppParams.LOCAL_STORAGE_WRONG_PAST_2_DAYS_KEY, str_now)
    }

    static may_pass_wrong_past_2_days = () => {
        let now = new Date()
        let str_now = now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate()
        let last_repeated_str_date = localStorage.getItem( AppParams.LOCAL_STORAGE_WRONG_PAST_2_DAYS_KEY )
        return last_repeated_str_date != null && last_repeated_str_date == str_now
    }
}



export default AppParams