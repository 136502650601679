import { Fragment, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Button, Container, Spinner, Stack } from "react-bootstrap"
import ImportanceLevel from "../utils/Importance-Level"
import AppParams from "../../store/App-Parameters"
import ErrorModal from "../utils/Error-Modal"
import useHttp from "../../hooks/useHttp"
import { useDispatch } from "react-redux";
import { wordpack_actions } from "../../store/Wordpack-Slice";

const WordPackSquareBtn = (props) => {

    let wordpack_json = props.wordpack_json

    let dispatch = useDispatch()
    let navigate = useNavigate()
    let [show_modal, set_show_modal] = useState()
    let [modal_error_msg, set_modal_error_msg] = useState("")
    let [downloaded, set_downloaded] = useState(false)
    let [is_downloading, set_is_downloading] = useState(false)
    let { isLoading, error, sendRequest } = useHttp();

    let style_mapping = {}
    style_mapping[""+ImportanceLevel.neutral] = "word_pack_square_btn_container_neutral"
    style_mapping[""+ImportanceLevel.upcoming] = "word_pack_square_btn_container_upcoming"
    style_mapping[""+ImportanceLevel.recommended] = "word_pack_square_btn_container_recommended"
    style_mapping[""+ImportanceLevel.required] = "word_pack_square_btn_container_required" 
    style_mapping[""+ImportanceLevel.ok] = "word_pack_square_btn_container_ok"


    useEffect( () => {  if(wordpack_json) set_downloaded( localStorage.getItem( String(wordpack_json.wordpack_id) ) != null ) }, [])

    const delete_wordpack_from_localStorage = () => {
        localStorage.removeItem( String(wordpack_json.wordpack_id) )
        set_downloaded(false)
    }


    const persist_wordpack_to_localStorage = () => {
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},
            body: {
                "service_type" : "get_wordpack_by_id",
                "wordpack_id" : wordpack_json.wordpack_id
            },
            onSuccessFunction : (response_json) => {
                set_is_downloading(false)
                if(response_json.result === "success") {
                    localStorage.setItem( String(wordpack_json.wordpack_id), JSON.stringify(response_json.reponse_payload) )
                    set_downloaded( true )
                } else {
                    set_modal_error_msg( response_json.reponse_payload )
                    set_show_modal(true)
                }
            }
        };
        set_is_downloading(true)
        sendRequest(requestConfig);
    }


    const continue_with_training_style = () => {
        AppParams.wordpack_id = wordpack_json.wordpack_id
        AppParams.training_style = 1000 <= AppParams.wordpack_id ? AppParams.HANZI : AppParams.FORWARD
        dispatch( wordpack_actions.set_training_style( { training_style : AppParams.training_style } ))
        if( !navigator.onLine && localStorage.getItem( String(wordpack_json.wordpack_id) ) == null ) {
            set_modal_error_msg( "Offline und Wordpack ist offline nicht verfügbar." )
            set_show_modal(true)
        } else
            navigate("/unified-trainer")
    }


    return (
        <Fragment>
            { show_modal && <ErrorModal set_show_modal={set_show_modal} error_msg={modal_error_msg}/>}
            { wordpack_json == undefined && <div></div> }
            { wordpack_json != undefined && 
                <Container onClick={() => continue_with_training_style()} className="p-1 m-0 word_pack_square_btn_outer_container">
                    <Stack direction="horizontal" gap={2} className="col-md-6 mx-auto">
                        <p className="p-0 m-0 word_pack_square_btn_title_p">WordPack</p>
                        { (!downloaded && !is_downloading) && <div onClick={ (event) => { /* https://stackoverflow.com/q/387736/1020704 */ event.stopPropagation(); persist_wordpack_to_localStorage() } } className="word_pack_square_btn_downlaod_btn_div">⇩</div> }
                        { is_downloading && <Spinner className="p-0 m-0" size="sm"/>}
                        { (downloaded && !is_downloading) && <div onClick={ (event) => { event.stopPropagation(); delete_wordpack_from_localStorage() } } className="word_pack_square_btn_delete_btn_div font_Segoe_UI_Symbol">✗</div> }
                    </Stack>
                    <p className="p-0 ps-1 m-0 word_pack_square_btn_number_p">{wordpack_json.wordpack_id}</p>
                    <p className={style_mapping[""+wordpack_json.importance_level] + " p-0 m-0"}>Vor { wordpack_json.delta_days } Tagen</p>
                    <p className={style_mapping[""+wordpack_json.importance_level] + " p-0 m-0"}>{ wordpack_json["0_cnt"] } Wdh</p>
                </Container>
            }
        </Fragment>
    )

}

export default WordPackSquareBtn