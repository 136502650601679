import { Fragment, useEffect, useRef, useState } from "react"
import StruggleButtons from "./Struggle-Buttons"
import { Button, Container, Spinner, Stack } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux';
import { wordpack_actions } from "../../store/Wordpack-Slice";
import { useNavigate } from "react-router-dom";
import useHttp from "../../hooks/useHttp"
import ErrorModal from "../utils/Error-Modal"
import uuidv4 from "../utils/Utils";
import NetworkStatus from "../utils/Network-Status";
import SyncManager from "../utils/Sync-Manager";
import AppParams from "../../store/App-Parameters"
import CommentsEditor from "../Comments-Editor";

const UnifiedTraining = (props) => {

    const [is_initializing, set_is_initializing] = useState(true)

    // ACHTUNG: Damit die EventListener richtig funktionieren muss hier dieser Spaghetti Code geshcriben werden.
    // GRUND: Die EventListener, die am Window-Objekt hängen können nicht auf reveal oder set_reveal zugreifen
    //        D.h. mit den Tasten kann man nicht direkt auf React-Elemente zugreifen
    const [reveal, set_reveal_2nd] = useState(false)
    const set_reveal = (value) => {
        window.reveal_helper = value
        set_reveal_2nd(value)
    }
    window.set_reveal = set_reveal
    const dispatch = useDispatch()
    const words = useSelector( state => state.wordpack.words )
    window.words = words //Dito, wie oben
    let training_style = useSelector( state => state.training_style )
    const current_words_index = useSelector( state => state.wordpack.current_words_index )
    const correct_count = useSelector( state => state.wordpack.correct_count )
    const can_undo = useSelector( state => state.wordpack.can_undo )

    const navigate = useNavigate()
    let { isLoading, error, sendRequest } = useHttp();
    let [show_modal, set_show_modal] = useState(false)
    let [modal_error_msg, set_modal_error_msg] = useState("")


    useEffect( () => {
        if(window.unified_training_event_listeners_were_added == null) {
            window.addEventListener("keydown", (event) => { handle_key_down_event(event) } )
            window.addEventListener("keyup", (event) => { handle_key_up_event(event) } )
            window.unified_training_event_listeners_were_added = true
        } 

        if( !navigator.onLine && localStorage.getItem( String(AppParams.wordpack_id) ) != null ) {
            let words = JSON.parse(localStorage.getItem( String(AppParams.wordpack_id) ))
            dispatch( wordpack_actions.initialize_wordpack( { wordpack_id: AppParams.wordpack_id, words : words } )  )
            set_is_initializing(false)
        } 

        const fetch_other_training = () => {
            const requestConfig = {
                url : window.baseURL + "data_service",
                method : "POST",
                headers : { "Content-Type" : "application/json"},
                body: {
                    "service_type" : AppParams.wordpack_id == AppParams.STRUGGLE_WORDPACK_ID ? "get_struggle_words" : "get_wrong_past_2_days",
                    "training_style" : AppParams.training_style // training_style <-- aus dem ReduxStore!
                },
                onSuccessFunction : (response_json) => {
                    if(response_json.result === "success") {
                        // Falls eine leere Wörterliste zurückgegeben wird, dann einfach überspringen
                        if( response_json.reponse_payload.length == 0 ) {
                            AppParams.set_wrong_past_2_days_passed()
                            set_is_initializing(false)
                            navigate("/")
                        } else {
                            dispatch( wordpack_actions.initialize_wordpack( { wordpack_id: AppParams.wordpack_id, words : response_json.reponse_payload } )  )
                            set_is_initializing(false)
                        }
                    } else {
                        set_modal_error_msg( response_json.reponse_payload )
                        set_show_modal(true)
                    }
                }
            };
            sendRequest(requestConfig);
        }

        const fetch_normal_training = (wordpack_id) => {
            const requestConfig = {
                url : window.baseURL + "data_service",
                method : "POST",
                headers : { "Content-Type" : "application/json"},
                body: {
                    "service_type" : "get_wordpack_by_id",
                    "wordpack_id" : wordpack_id
                },
                onSuccessFunction : (response_json) => {
                    if(response_json.result === "success") {
                        dispatch( wordpack_actions.initialize_wordpack( { wordpack_id: wordpack_id, words : response_json.reponse_payload } )  )
                        set_is_initializing(false)
                    } else {
                        set_modal_error_msg( response_json.reponse_payload )
                        set_show_modal(true)
                    }
                }
            };
            sendRequest(requestConfig);
        }
        
        
        if( AppParams.wordpack_id == AppParams.STRUGGLE_WORDPACK_ID ||
            AppParams.wordpack_id == AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID ) 
            fetch_other_training()
        else if( 0 <= AppParams.wordpack_id ) 
            fetch_normal_training( AppParams.wordpack_id )
        else 
            navigate("/")
    }, [])


    useEffect( () => {
        if( current_words_index == -1 && words != null ) {
            let uuid = uuidv4()
            let json_payload = {
                "service_type" : "update_training_statistics",
                "wordpack_id" : AppParams.wordpack_id,
                "training_style" : AppParams.training_style, // training_style <-- aus dem ReduxStore!
                "stats" : create_learning_statistics(),
                "uuid" : uuid,
            }
            
            AppParams.wordpack_id == AppParams.STRUGGLE_WORDPACK_ID && AppParams.set_struggle_words_passed()
            AppParams.wordpack_id == AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID && AppParams.set_wrong_past_2_days_passed()

            if( !navigator.onLine ) {
                SyncManager.save_sync_data(json_payload)
                set_modal_error_msg( "Du bist offline! Die Daten wurden im localStorage gespeichert!" )
                set_show_modal(true)
            } else {
                const requestConfig = {
                    url : window.baseURL + "data_service",
                    method : "POST",
                    headers : { "Content-Type" : "application/json"},
                    body: json_payload, // Siehe oben!
                    onSuccessFunction : (response_json) => {
                        dispatch( wordpack_actions.reset_wordpack())
                        if(response_json.result == "success") {
                            SyncManager.mark_as_synced(response_json.uuid)
                            reset_unified_training()
                            navigate("/")
                        } else {
                            SyncManager.save_sync_data(json_payload)
                            set_modal_error_msg( response_json.reponse_payload + " - Die Daten wurden sicherheitshalber im localStorage des Browsers zwischengespeichert.")
                            set_show_modal(true)
                        }
                    }
                }
                if(AppParams.wordpack_id == AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID) {
                    dispatch( wordpack_actions.reset_wordpack())
                    reset_unified_training()
                    navigate("/")
                } else {
                    sendRequest(requestConfig)
                }
            }
        } else {
            set_reveal(false);
        }
        
    } , [current_words_index])


    // Setze alles zurück für den nächsten Aufruf des Unified-Trainings
    const reset_unified_training = () => {
        set_is_initializing(true)
        set_reveal(false)
        keys_pressed_down.current = {}
    }

    const create_learning_statistics = () => {
        let stats = []
        for( let idx = 0; idx < words.length; ++idx) {
            stats.push({
                word_id : words[idx].word_id,
                wrong_count : words[idx].wrong_count
            })
        }
        return stats
    }


    const keys_pressed_down = useRef({})
    const handle_key_down_event = (event) => {
        
        let key = event.key
        if( window.words == null) {
            return
        }
        if( !["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"].includes(key) )
            return

        // Verhindere multiples Aufrufen, bzw. mehrfaches Auslesen eines key_pressed_down events
        if( keys_pressed_down.current[key] != null && keys_pressed_down.current[key]) 
            return
        else
            keys_pressed_down.current[key] = true

        //Hinweis: Diese keys_pressed_down Funktion hängt als listener am Window Objekt. Daher kann man aus dieser Methode heraus nicht auf reveal zugreifen
        //         Paradoxerweise kann man aber set_reveal aufrufen...
        if( window.reveal_helper ) {
            if( key === "ArrowUp" ) 
                dispatch(wordpack_actions.get_next_word( { correct : false } ))
            if( key === "ArrowDown" ) 
                dispatch(wordpack_actions.get_next_word( { correct : null } ))
            if( key === "ArrowRight" ) 
                dispatch(wordpack_actions.get_next_word( { correct : true } ))
            window.set_reveal(false)
        } else {
            if( key === "ArrowRight") {
                window.set_reveal(true)
            }
        }

        if( key === "ArrowLeft") {
            dispatch(wordpack_actions.undo_to_previous_word())
            window.set_reveal(false)
        }
    }

    const handle_key_up_event = (event) => {
        let key = event.key
        if( !["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"].includes(key) )
            return
        keys_pressed_down.current[key] = false
    }


    return (
        <Fragment>
            <NetworkStatus />
            { show_modal && <ErrorModal set_show_modal={set_show_modal} error_msg={modal_error_msg}/>}

            { is_initializing &&
                <Fragment>
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        <div className="text-center waiting_animation_div p-5">
                            <Spinner className="mb-4" animation="border" variant="success" />
                            <h3>Lade Wörter...</h3>
                        </div>
                    </div>
                </Fragment>}


            { (!is_initializing && current_words_index < 0 ) && 
            <Fragment>
                <div className="d-flex align-items-center justify-content-center vh-100">
                    <div className="text-center waiting_animation_div p-5">
                        <Spinner className="mb-4" animation="border" variant="success" />
                        <h3>Aktualisiere Statistiken...</h3>
                    </div>
                </div>
            </Fragment>}


            { (!is_initializing && 0 <= current_words_index ) && 
            <Fragment>
                <Stack>
                    <Stack direction="horizontal" className="p-2" style={{width: "100%"}}>
                        <StruggleButtons style={{width: "100%"}}/>
                        <Stack className="text-end" style={{width: "50%"}}>
                            { ![AppParams.STRUGGLE_WORDPACK_ID, AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID].includes(AppParams.wordpack_id) && <p className="m-0 p-0">WP {AppParams.wordpack_id}: {words.length-correct_count} / {words.length}</p> }
                            { [AppParams.STRUGGLE_WORDPACK_ID, AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID].includes(AppParams.wordpack_id) && <p className="m-0 p-0">{words.length-correct_count} / {words.length}</p> }
                            <p className="m-0 p-0">{words[current_words_index].appearances} mal erschienen</p>
                        </Stack>
                    </Stack>

                    { AppParams.wordpack_id == AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID && 
                        <Container className="p-2 mb-2 text-center unified_training_wrong_past_2_days_div">
                            Falsche Wörter der letzten 2 Tage
                        </Container> }
                    { AppParams.wordpack_id != AppParams.WRONG_PAST_2_DAYS_WORDPACK_ID && <Container className="mb-2 unified_training_spacing_div" /> } 

                    <Container className="p-2 unified_training_text_container">
                        { AppParams.training_style == AppParams.HANZI && <h1 className="text-center">{ words[current_words_index].chinese }</h1> }
                        { AppParams.training_style != AppParams.HANZI && <h2 className="text-center">{ words[current_words_index].german }</h2> }
                    </Container>
                    
                    <Container>
                        <Stack direction="horizontal" className="float-end">
                            { can_undo && <Button className="symbol_btn m-2" variant="secondary" size="lg" onClick={ () => { dispatch(wordpack_actions.undo_to_previous_word()) } }>↺</Button> }
                            { reveal && <Button className="symbol_btn m-2" variant="danger" size="lg" onClick={ () => { dispatch(wordpack_actions.get_next_word( { correct : false } )) } }>✗</Button> }
                            { !reveal && <div className="symbol_btn_placeholder m-2"></div> }
                            <Button className="symbol_btn ms-2" variant="secondary" size="lg" onClick={ () => { set_reveal(true) } }>➥</Button>
                        </Stack>
                    </Container>
                    { reveal && 
                        <Container>
                            <Stack direction="horizontal" className="float-end">
                                <Button className="symbol_btn m-2" variant="secondary" size="lg" onClick={ () => { dispatch(wordpack_actions.get_next_word( { correct : null } )) } }>✗</Button>
                                <Button className="symbol_btn symbol_btn_green ms-2" variant="success" size="lg" onClick={ () => { dispatch(wordpack_actions.get_next_word( { correct : true } )) } }>✓</Button>
                            </Stack>
                        </Container>
                    }
                </Stack>

                { reveal && 
                    <Container className="p-2 unified_training_text_container unified_training_no_bottom_border">
                        <h2 className="text-center">{AppParams.training_style == AppParams.HANZI ? words[current_words_index].german : words[current_words_index].chinese}</h2>
                    </Container>
                } { reveal && 
                    <Container className="p-2 unified_training_text_container unified_training_no_top_border">
                        <h2 className="text-center">{words[current_words_index].pinyin}</h2>
                    </Container>
                } { reveal && 
                    <Container className="mt-5 p-2 unified_training_text_container">
                        <CommentsEditor update_redux_store={true} show_add_comment={ (words[current_words_index].comments == null || 0 == words[current_words_index].comments.length) } word={words[current_words_index]}/>
                    </Container>
                } 
            </Fragment>
            }
        </Fragment>
        )
}

export default UnifiedTraining