import { useState, useEffect, Fragment } from 'react';
import "../../css/styles.css"

const NetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showOnlineNotification, setShowOnlineNotification] = useState(false);
  
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
  
      // If the device comes online, display the notification for 5 seconds
      if (navigator.onLine) {
        setShowOnlineNotification(true);
        setTimeout(() => {
          setShowOnlineNotification(false);
        }, 5000);
      }
    };
  
    useEffect(() => {
      window.addEventListener('online',  updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
  
      return () => {
        window.removeEventListener('online',  updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
      };
    }, []);
  
    return (
        <Fragment>
            { !isOnline && <p className={ isOnline ? "network_status_online" : "network_status_offline" }>Your device is currently: {isOnline ? "Online" : "Offline"}</p> }
            { showOnlineNotification && <p className="network_status_online">You're back online!</p>}
        </Fragment>
    );
};

export default NetworkStatus;