import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/styles.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider }  from "react-redux"
import store from './store/Redux-Store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
        { /* Hinweis Reihenfolge von BrowserRouter und Provider sind nicht wichtig */ }
        <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
        </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();