import { createSlice  } from "@reduxjs/toolkit";
import shuffle from "lodash.shuffle"

/*
{
    "word_id" : int,
    "chinese" : string,
    "pinyin" : string,
    "german" : string,
    "comments" : string,

    // Added in "set_wordpack"
    "appearances" : int,
    "wrong_count" : int,
    "correctly_predicted" : bool
}
*/
const initial_state = { 
    wordpack_id : -1,
    training_style : null,
    words : null, 
    current_words_index : -1,
    correct_count : 0,
    can_undo : false,
    // Verwende hier die Settings vom letzten Undo
    undo_delta : {}
}

const wordpack_slice = createSlice({
    name: "wordpack_slice",
    initialState: initial_state,
    reducers: {
        reset_wordpack(state) {
            state.wordpack_id = -1
            state.words = null
            state.current_words_index = -1
            state.correct_count = 0
            state.can_undo = false
            // Verwende hier die Settings vom letzten Undo
            state.undo_delta = {}
        },

        initialize_wordpack(state, action) {
            state.wordpack_id = action.payload.wordpack_id
            state.words = shuffle(action.payload.words)
            state.current_words_index = state.words.length == 0 ? -1 : 0
            state.words.map( word => { 
                word.appearances = 0
                word.wrong_count = 0
                word.correctly_predicted = false
                return word
            })
        },

        set_training_style( state, action ) {
            state.training_style = action.payload.training_style
        },

        update_struggle_types(state, action) {
            state.words[state.current_words_index].forward_struggle = action.payload.forward_struggle
            state.words[state.current_words_index].inverse_struggle = action.payload.inverse_struggle
            state.words[state.current_words_index].listening_struggle = action.payload.listening_struggle
        },

        get_next_word(state, action) {
            state.undo_delta = {
                correctly_predicted : false, // <-- immer der Fall!
                previous_word_id : state.words[state.current_words_index].word_id,
                appearances : state.words[state.current_words_index].appearances,
                wrong_count : state.words[state.current_words_index].wrong_count,
                correct_count : state.correct_count,
            }
            state.can_undo = true

            state.words[state.current_words_index].appearances++
            if( action.payload.correct === true )
                state.words[state.current_words_index].correctly_predicted = true
            if( action.payload.correct === false )
                state.words[state.current_words_index].wrong_count++

            let all_words_correctly_predicted = true
            state.correct_count = 0
            for( let idx = 0; idx < state.words.length; ++idx ) {
                all_words_correctly_predicted = all_words_correctly_predicted && state.words[idx].correctly_predicted
                if( state.words[idx].correctly_predicted )
                    state.correct_count++
            }
            if( all_words_correctly_predicted ) 
                state.current_words_index = -1
            else 
                do {
                    state.current_words_index = (state.current_words_index + 1) % state.words.length
                } while( state.words[state.current_words_index].correctly_predicted )
        },

        undo_to_previous_word(state) {
            for( let idx = 0; idx < state.words.length; ++idx ) 
                if( state.words[idx].word_id == state.undo_delta.previous_word_id ) 
                    state.current_words_index = idx
            state.words[state.current_words_index].correctly_predicted = state.undo_delta.correctly_predicted
            state.words[state.current_words_index].appearances = state.undo_delta.appearances
            state.words[state.current_words_index].wrong_count = state.undo_delta.wrong_count
            state.correct_count = state.undo_delta.correct_count
            state.can_undo = false
        },

        update_comment(state, action) {
            state.words[state.current_words_index].comments = action.payload.new_comment
        },
    }
})

export const wordpack_actions = wordpack_slice.actions
export default wordpack_slice