import { useState, useCallback } from 'react';

const useHttp = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);
    const sendRequest = useCallback(async (requestConfig) => {
     setIsLoading(true);
     setError(null);
     try {
        const response = await fetch(requestConfig.url, {
                method: requestConfig.method ? requestConfig.method : "GET",
                headers: requestConfig.headers ? requestConfig.headers : {},
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });
        if (!response.ok) 
            throw new Error('Request failed!');
        
        const data = await response.json();
        requestConfig.onSuccessFunction(data);
     } catch (err) {
        setError(err.message || 'Something went wrong!');
     }
     setIsLoading(false);
   }, []);

   //return { isLoading: isLoading, error : error, sendRequest: sendRequest};
   // Kurzversion von der Zeile ergibt gleiches Ergebnis:
   return { isLoading, error, sendRequest};
}

export default useHttp;