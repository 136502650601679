import { Fragment, useState, useEffect } from "react"
import SearchInput from "../search/Search-Input"
import { Container, Row, Col, Spinner } from "react-bootstrap"
import useHttp from "../../hooks/useHttp"
import "../../css/styles.css"
import { useNavigate } from 'react-router-dom';
import AppParams from "../../store/App-Parameters"
import WordPackSquareBtn from "./Word-Pack-Square-Btn"
import RankCalculator from "../utils/Importance-Level"
import ImportanceLevel from "../utils/Importance-Level"
import { SyncNotifier } from "../utils/Sync-Manager";
import NetworkStatus from "../utils/Network-Status"

const ChooseSortedWordPack = () => {

    let navigate = useNavigate();
    let { isLoading, error, sendRequest } = useHttp();
    let [error_msg, set_error_msg] = useState(null);
    let [hanzi_wordpacks_array, set_hanzi_wordpacks_array] = useState([])
    let [normal_wordpacks_array, set_normal_wordpacks_array] = useState([])


    // Nur beim Neuladen der Seite ausführen
    useEffect( () => {
        !AppParams.may_pass_wrong_past_2_days() && navigate("/choose-struggle-type")
        !AppParams.may_pass_struggle_words() && navigate("/choose-struggle-type")

        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},
            body: {
                "service_type" : "get_all_wordpacks_including_hanzi",
            },
            onSuccessFunction : (responseJson) => {
                if(responseJson.result === "success") {
                    set_normal_wordpacks_array( ImportanceLevel.sort_by_importance_level(responseJson.reponse_payload, "normal") )
                    set_hanzi_wordpacks_array( ImportanceLevel.sort_by_importance_level(responseJson.reponse_payload, "hanzi") )
                } else {
                    set_error_msg("Something went wrong!")
                }
            }
        };
        sendRequest(requestConfig);
    }, [])


    const render_normal_wordpacks = () => {
        let rows=[]
        for( let idx = 0; idx < normal_wordpacks_array.length; idx+=2 ) {
            rows.push(
                <Row key={idx} className="p-0 m-0 mt-2">
                    <Col className="ps-0 pe-1 m-0"><WordPackSquareBtn wordpack_json={normal_wordpacks_array[idx]}/></Col>
                    { (idx + 1) < normal_wordpacks_array.length && <Col className="ps-1 pe-1 m-0"><WordPackSquareBtn wordpack_json={normal_wordpacks_array[idx+1]} /></Col> }
                </Row>
            )
        }
        return rows
    }


    return (
        <Fragment>
            <Container className="text-center py-4 px-2">
                <NetworkStatus />
                <SyncNotifier />
                { (error_msg && error_msg.length > 0) && <p className="error-p">Logic Error: {error_msg}</p>}
                { (error && error.length > 0) && <p className="error-p">useHttp Error: {error}</p>}
                <Row className="mb-3">
                    <Col>
                        <SearchInput/>
                    </Col>
                </Row>
                { isLoading && 
                <div>
                    <Spinner animation="border" style={{color: "white"}}/>
                    <p style={ {color: "white"} }>Lade Wordpacks...</p>
                </div>
                }

                <Row className="p-0 m-0">
                    <Col className="p-0 m-0 col-8 text-center">
                        <p className="p-0 m-0 me-1 choose_sorted_word_pack_column_title_p">Vorwärts Normal</p>
                    </Col>
                    <Col className="p-0 m-0 col-4 text-center">
                        <p className="p-0 m-0 ms-1 choose_sorted_word_pack_column_title_p">HanZi</p>
                    </Col>
                </Row>

                <Row className="p-0 m-0">
                    <Col className="p-0 m-0 col-8">
                        <Container className="p-0 m-0">
                            { normal_wordpacks_array.length > 0 && render_normal_wordpacks()}
                        </Container>
                    </Col>
                    <Col className="p-0 m-0 col-4">
                        <Container className="p-0 m-0">
                            { hanzi_wordpacks_array.map( hanzi_wordpack => {
                                return (
                                    <Row key={hanzi_wordpack.wordpack_id} className="p-0 m-0 mt-2">
                                        <Col className="ps-1 pe-0 m-0"><WordPackSquareBtn wordpack_json={hanzi_wordpack}/></Col>
                                    </Row>
                                )
                            } )}
                            <WordPackSquareBtn/>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}

export default ChooseSortedWordPack