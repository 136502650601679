import { Fragment, useRef, useState } from "react";
import { Form, Button, Col, Row, Stack } from "react-bootstrap";
import '../../css/styles.css';
import SearchResult from "./Search-Result";
import useHttp from '../../hooks/useHttp';
import Spinner from 'react-bootstrap/Spinner';

const SearchInput = (props) => {

    let search_input_ref = useRef();
    let { isLoading, error, sendRequest } = useHttp();
    let [error_msg, set_error_msg] = useState("");
    let [search_results, set_search_results] = useState([])

    const submitHandler = (event) => {
        event.preventDefault();
        const search_term = search_input_ref.current.value;
        if( 0 < search_term.length ) {
            const requestConfig = {
                url : window.baseURL + "data_service",
                method : "POST",
                headers : { "Content-Type" : "application/json"},
                body: {
                    "service_type" : "search_term",
                    "search_term" : search_term,
                },
                onSuccessFunction : (responseJson) => {
                    if(responseJson.result === "success") {
                        set_search_results( responseJson.reponse_payload )
                    } else {
                        set_error_msg("Something went wrong!")
                    }
                }
            };
            sendRequest(requestConfig);
        }
    }

    const clearHandler = (event) => {
        set_search_results([])
        search_input_ref.current.value = ""
    }

    return (<Fragment>
        
        <Row>
            <Form>
                <Stack direction="horizontal" gap={2}>
                    <Form.Control ref={search_input_ref} type="text" placeholder="Search Term" />
                    { (!isLoading && search_results.length == 0 ) && <Button variant="secondary" type="submit" onClick={submitHandler}>🔍</Button> }
                    { (!isLoading && search_results.length > 0 ) && <Button variant="secondary" onClick={clearHandler}>❌</Button> }
                    { isLoading &&  <Spinner animation="border" variant="spinner-border text-warning" />}
                </Stack>
            </Form>
        </Row>

        { error_msg.length > 0 && <p className="">{ error_msg }</p> }

        { search_results.length > 0 && 
            <Row className="search_result_container mt-2">
                { search_results.map( (item,index) => {
                    return <SearchResult key={index} search_result={item} position={index} />
                })}
            </Row>
        }
    </Fragment>)
}

export default SearchInput;