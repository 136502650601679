// import SyncManager from '../utils/SyncManager';

import { Fragment, useEffect, useState } from "react";
import "../../css/styles.css"
import { Button, Spinner, Stack } from "react-bootstrap";
import useHttp from "../../hooks/useHttp"
import ErrorModal from "./Error-Modal"

class SyncManager {

    static save_sync_data = (json_payload) => {
        let current_unsynced_data = JSON.parse(localStorage.getItem("unsychronzed-wordpacks"))
        current_unsynced_data = current_unsynced_data == null ? [] : current_unsynced_data
        current_unsynced_data.push( json_payload )
        localStorage.setItem("unsychronzed-wordpacks", JSON.stringify(current_unsynced_data));
    }

    static get_unsynced_data = () => {
        let current_unsynced_data = JSON.parse(localStorage.getItem("unsychronzed-wordpacks"))
        return current_unsynced_data == null ? [] : current_unsynced_data
    }

    static mark_as_synced = (uuid) => {
        let current_unsynced_data = JSON.parse(localStorage.getItem("unsychronzed-wordpacks"))
        if( current_unsynced_data != null ) {
            for( let idx = 0; idx < current_unsynced_data.length; ++idx ) {
                if( current_unsynced_data[idx].uuid == uuid ) {
                    current_unsynced_data.splice(idx, 1) // idx to remove, 1 = num element to remove
                    localStorage.setItem("unsychronzed-wordpacks", JSON.stringify(current_unsynced_data));
                    return // after deletion is completed...
                }
            }
        }
    }

}

const SyncNotifier = () => {

    let { isLoading, error, sendRequest } = useHttp();
    let [show_modal, set_show_modal] = useState(false)
    let [modal_error_msg, set_modal_error_msg] = useState("")

    const [localStorage_unsynced_elem_count, set_localStorage_unsynced_elem_count] = useState(0)
    useEffect(() => { update_component()}, [])



    const update_component = () => {
        let current_unsynced_data = JSON.parse(localStorage.getItem("unsychronzed-wordpacks"))
        current_unsynced_data = current_unsynced_data == null ? [] : current_unsynced_data
        set_localStorage_unsynced_elem_count( current_unsynced_data.length )
    }


    const sync_now = () => {
        let unsynced_data = SyncManager.get_unsynced_data()
        let json_payload = unsynced_data[0]
        const requestConfig = {
            url : window.baseURL + "data_service",
            method : "POST",
            headers : { "Content-Type" : "application/json"},
            body: json_payload,
            onSuccessFunction : (response_json) => {
                if(response_json.result == "success") {
                    SyncManager.mark_as_synced(response_json.uuid)
                } else {
                    set_modal_error_msg( response_json.reponse_payload + " - Die Daten wurden wurden nicht gelöscht, sondern liegen noch im localStorage.")
                    set_show_modal(true)
                }
                update_component()
            }
        };
        sendRequest(requestConfig);
    }


    return (
            <Fragment>
                { show_modal && <ErrorModal set_show_modal={set_show_modal} error_msg={modal_error_msg}/>}
                { 0 < localStorage_unsynced_elem_count && 
                    <Fragment>
                        <Stack direction="vertical" className="sync_manager_unscynced_elements px-5 py-4 mb-4">
                            <p className="p-0 m-0" style={{ fontSize : "100px"}}>⚠️</p>
                            { 1 == localStorage_unsynced_elem_count && <p className="pt-0 mt-0">Es befindet sich {localStorage_unsynced_elem_count} unsynchronisierte Training Session im localStorage.</p>}
                            { 1 < localStorage_unsynced_elem_count && <p className="pt-0 mt-0">Es befinden sich {localStorage_unsynced_elem_count} unsynchronisierte Training Sessions im localStorage.</p>}
                            { !isLoading && <Button variant="warning" onClick={ () => { sync_now() } }>Jetzt Synchronisieren</Button>}
                            { isLoading && <Spinner animation="border" variant="spinner-border text-warning"/> }
                        </Stack>
                    </Fragment>
                }
            </Fragment>
    )
}

export default SyncManager
export { SyncNotifier }
